<template>
    <div >    <div class="py-2">
        <h4>Alarms</h4>

        <h5>Vibrace</h5>
        <button class="btn btn-sm btn-success mr-1" @click="addNewRowV" v-if="!addingNewV">Přidat nové </button>
        <table class="table alarms">
            <tr >
                <th rowspan="2" class="tc1">{{$t("motor.osova-vyska")}}</th>
        <!--        <th colspan="2" class="text-center vyborne">{{$t("admin.alarm.vyborne")}}</th>
                <th colspan="2" class="text-center dostatecne">{{$t("admin.alarm.dostatecne")}}</th>-->
                <th colspan="2" class="text-center spatne">{{$t("admin.alarm.nedostatecne")}}</th>
                <th colspan="2"></th>
            </tr>
            <tr>
         <!--         <th class="vyborne">od</th><th class="vyborne">do</th>
                <th  class="dostatecne">od</th><th class="dostatecne">do</th>-->
                <th class="spatne">od</th><th class="spatne">do</th>
            </tr>
            <tr v-if="addingNewV">
                <td class="tc1"><input v-model="newRow.axisheight" /></td>
                <!--    <td class="vyborne"><input v-model="newRow.v1min" /></td>
                <td class="vyborne"><input v-model="newRow.v1max" /></td>

                <td class="dostatecne"><input v-model="newRow.v2min" /></td>
                <td class="dostatecne"><input v-model="newRow.v2max" /></td>-->

                <td class="spatne"><input v-model="newRow.v3min" /></td>
                <td class="spatne"><input v-model="newRow.v3max" /></td>

                <th class="d-flex">
                            <button class="btn btn-sm btn-success mr-1" @click="saveNewData()">Přidat</button>
                            <button class="btn btn-sm btn-secondary" @click="cancelNewData()">Zrušit</button>
                </th>
            </tr>

            <tr v-for="(row) in vibrace" :key="row.id">
                <td class="tc1"><b>{{row.axisheight}}</b></td>
          <!--         <td class="vyborne"><input v-model="row.v1min"  :disabled="row.disabled"/></td>
                <td class="vyborne"><input v-model="row.v1max " :disabled="row.disabled"/></td>

                <td class="dostatecne"><input v-model="row.v2min" :disabled="row.disabled"/></td>
                <td class="dostatecne"><input v-model="row.v2max" :disabled="row.disabled"/></td>-->

                <td class="spatne"><input v-model="row.v3min" :disabled="row.disabled"/></td>
                <td class="spatne"><input v-model="row.v3max" :disabled="row.disabled"/></td>

                <th v-if="row.disabled" class="d-flex">

                    <button :disabled="editedIntervals && editedIntervals.id != row.id" class="btn btn-sm btn-secondary" @click="editButton(row)">Upravit</button>
                    <button :disabled="editedIntervals && editedIntervals.id != row.id" class="btn btn-sm btn-outline-danger ml-2" @click="destroyButton(row)">Smazat</button>

                </th>
                <th v-else class="d-flex">
                    <button class="btn btn-sm btn-success mr-1" @click="saveAlarm(row)">Uložit</button>
                    <button class="btn btn-sm btn-secondary" @click="cancelEdit(row)">Zrušit</button>
                </th>
            </tr>
        </table>


        <!------------------------------------------------------------------------------------------------------------------------------>

        <hr>
        <h5>Teploty</h5><button class="btn btn-sm btn-success mr-1" @click="addNewRowT" v-if="!addingNewT">Přidat nové </button>
        <table class="table alarms">
            <tr >
                <th rowspan="2" class="tc1">{{$t("motor.pocet-polu")}}</th>
          <!--         <th colspan="2" class="text-center vyborne">{{$t("admin.alarm.vyborne")}}</th>
                <th colspan="2" class="text-center dostatecne">{{$t("admin.alarm.dostatecne")}}</th>-->
                <th colspan="2" class="text-center spatne">{{$t("admin.alarm.nedostatecne")}}</th>
                <th colspan="2"></th>
            </tr>
            <tr>
         <!--          <th class="vyborne">od</th><th class="vyborne">do</th>
                <th  class="dostatecne">od</th><th class="dostatecne">do</th>-->
                <th class="spatne">od</th><th class="spatne">do</th>
            </tr>
            <tr v-if="addingNewT">
                <td class="tc1"><input v-model="newRow.pole" /></td>
      <!--             <td class="vyborne"><input v-model="newRow.t1min" /></td>
                <td class="vyborne"><input v-model="newRow.t1max" /></td>

                <td class="dostatecne"><input v-model="newRow.t2min" /></td>
                <td class="dostatecne"><input v-model="newRow.t2max" /></td>-->

                <td class="spatne"><input v-model="newRow.t3min" /></td>
                <td class="spatne"><input v-model="newRow.t3max" /></td>

                <th class="d-flex">
                    <button class="btn btn-sm btn-success mr-1" @click="saveNewData()">Přidat</button>
                    <button class="btn btn-sm btn-secondary" @click="cancelNewData()">Zrušit</button>
                </th>
            </tr>

            <tr v-for="(row) in teploty" :key="row.id">
                <td><b>{{row.pole}}</b></td>
             <!--      <td class="vyborne"><input v-model="row.t1min"  :disabled="row.disabled"/></td>
                <td class="vyborne"><input v-model="row.t1max" :disabled="row.disabled"/></td>

                <td class="dostatecne"><input v-model="row.t2min" :disabled="row.disabled"/></td>
                <td class="dostatecne"><input v-model="row.t2max" :disabled="row.disabled"/></td>-->

                <td class="spatne"><input v-model="row.t3min" :disabled="row.disabled"/></td>
                <td class="spatne"><input v-model="row.t3max" :disabled="row.disabled"/></td>

                <th v-if="row.disabled" class="d-flex">

                    <button :disabled="editedIntervals && editedIntervals.id != row.id" class="btn btn-sm btn-secondary" @click="editButton(row)">Upravit</button>
                    <button :disabled="editedIntervals && editedIntervals.id != row.id" class="btn btn-sm btn-outline-danger ml-2"  @click="destroyButton(row)">Smazat</button>

                </th>
                <th v-else class="d-flex">
                    <button class="btn btn-sm btn-success mr-1" @click="saveAlarm(row)">Uložit</button>
                    <button class="btn btn-sm btn-secondary" @click="cancelEdit(row)">Zrušit</button>
                </th>
            </tr>
        </table>

    </div>

    </div>
</template>

<script>

    const axios = require("axios");
    axios.defaults.withCredentials = true



    export default {
        name: "Alarms",
        data(){
            return{
                editedIntervals: null,
                addingNewV: false,
                addingNewT: false,
                newRow:{},
                settings:{

                }

            }
        }, computed: {
            me() {
                if (this.$store.getters.me)
                    return this.$store.getters.me;
                else
                    return {}
            },
            teploty(){
                var arr=[]
                for(let i=0; i<this.settings.length; i++){
                    if (this.settings[i].pole>0){
                        arr.push(this.settings[i])
                    }
                }
                return arr
            },
            vibrace(){
                var arr=[]
                for(let i=0; i<this.settings.length; i++){
                    if (parseInt(this.settings[i].axisheight)>0){
                        arr.push(this.settings[i])
                    }
                }
                return arr
            }
        },
        mounted() {

            this._loadSetup()

        },
        methods:{
            _loadSetup(){
                 axios.get(process.env.VUE_APP_API_ROOT_URL + "/alarms?"+new Date().getTime())
                    .then(response => {
                        if (response.status == 200) {
                            console.log('OK')
                            this.settings = response.data

                            for(let i=0; i<this.settings.length; i++){
                                this.settings[i].disabled = true
                            }

                        }
                    }).catch((err)=>{
                        console.error("problem cteni alarms", err)
                 })
            },
            saveNewData(){
                var that= this
                axios.post(process.env.VUE_APP_API_ROOT_URL + "/alarms", this.newRow)
                    .then(response => {
                        if (response.status == 201) {
                            console.log('OK')
                            that.addingNewV=false
                            that.addingNewT=false
                            let d = response.data
                            d.disabled = true
                            that.settings.push(d)

                        }
                    })
            },
            cancelNewData(){
                this.newRow={}
                this.addingNewV=false
                this.addingNewT=false
            },
            addNewRowT(){
                this.addingNewT=true
            },
            addNewRowV(){
                this.addingNewV=true
            },
            editButton(row){
                this.editedIntervals = {}
                for (var i in row){
                    this.editedIntervals[i] = row[i]
                }
                row.disabled=false
            },
            cancelEdit(row){
                console.log('this diadalal', row.id, this.editedIntervals.id)
               for (var i in this.editedIntervals){
                    if (i=='id') continue
                    row[i] = this.editedIntervals[i]
                }
                this.editedIntervals=null
               row.disabled = true



            },
            saveAlarm(row){
                 axios.put(process.env.VUE_APP_API_ROOT_URL + "/alarms/"+row.id, row)
                    .then(response => {
                        if (response.status == 200) {
                            console.log('OK')
                            row.disabled = true
                            this.editedIntervals = null;
                        }
                    })
            },
            destroyButton(row){

                if (!confirm("Opravdu smazat?")){
                    return
                }

                axios.delete(process.env.VUE_APP_API_ROOT_URL + "/alarms/"+row.id, row)
                    .then(response => {
                        if (response.status == 204) {
                            console.log('smazlo', row.id)

                             for(let i=0; i<this.settings.length; i++){
                                if (  this.settings[i].id == row.id){
                                    this.settings.splice(i,1)
                                }

                             }

                        }
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>


.tc1{ width:150px;}
</style>
